import { Vue, Component, Watch } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BTabs,
  BTab
} from 'bootstrap-vue';
import { ListVue, listVueMixin } from '@/utility/pagination/list-vue.mixin';
import { DEFAULT_ORDER_PAGE_SIZE } from '@/utility/pagination/pagination';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { producingListDispatcher } from './producingList/store/producing-list.module';
import ProducingList from './producingList/ProducingList.vue';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    BCardText,
    BImg,
    BBadge,
    BTabs,
    BTab,
    ProducingList
  },
  mixins: [
    listVueMixin({
      initialInput: {
        itemsPerPage: DEFAULT_ORDER_PAGE_SIZE,
        searchText: '',
        filterStatus: [] as DropdownOption[],
        filterEmployee: '',
        isOrderForDesign: false,
        isOrderForPrinting: false
      },
      dispatcher: producingListDispatcher
    })
  ]
})
export default class ProducingPage extends Vue {
  producingListDispatcher = producingListDispatcher;
  selectedTab = 0;
  editOrderId = 0;
  //selectedOrders: OrderListViewModel[] = [];
  previousTab = 0;

  $refs!: {
    producingListRef: any;
    designListRef: any;
    printingListRef: any;
  };

  // get isDisplayOrderNavbar() {
  //   return this.selectedOrders.length > 0 && this.selectedTab == 0;
  // }

  // setSelectedOrders(values: OrderListViewModel[]) {
  //   this.selectedOrders = [...values];
  // }

  resetTab() {
    window.scrollTo(0, 0);
    this.selectedTab = this.previousTab;
    this.editOrderId = 0;
    this.producingListDispatcher.load();
  }

  handleEditOrder(id) {
    this.previousTab = this.selectedTab;
    this.editOrderId = id;
    this.selectedTab = 1;
  }

  // onUpdateStatusSuccess() {
  //   this.$refs.orderListRef.reloadList(this.selectedOrders.map((s) => s.id));
  //   this.$refs.orderBillListRef.reloadList(
  //     this.selectedOrders.map((s) => s.id)
  //   );
  // }
}
