





















import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  BModal,
  BFormInput,
  BRow,
  BCol,
  BContainer,
  BFormGroup,
  BForm,
  BButton,
  BFormTextarea,
  BBadge
} from 'bootstrap-vue';
import { OrderDetailStatus, OrderDetailViewModel } from '@/api/api';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { enumToDropdownOptions } from '@/utility/utils';
import { OrderDetailStatusChangeFlow } from '@/utility/constants';

@Component({
  components: {
    BModal,
    BFormInput,
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BFormTextarea,
    BBadge
  }
})
export default class ProducingStatusSumary extends Vue {
  @Prop({ type: Array, required: true })
  orderDetails!: OrderDetailViewModel[];

  @Prop({ type: Number, required: true })
  deliveryProductCategoryId!: number;

  orderDetailStatusSumaryOptions: DropdownOption[] = enumToDropdownOptions(
    OrderDetailStatusSumary
  ).map((s) => {
    return {
      value: s.value,
      text: s.text
    };
  });

  getOrderDetailStatusReport() {
    const orderDetailStatusReport = this.orderDetailStatusSumaryOptions.map(
      (s) => {
        return {
          text: s.text,
          value: s.value,
          count: 0
        };
      }
    );

    this.orderDetails.forEach((detailItem) => {
      const statusFlow = OrderDetailStatusChangeFlow.find(
        (s) => s.status == detailItem.status
      );
      if (statusFlow) {
        [...statusFlow.previousValues, statusFlow.status].forEach((s) => {
          const index = orderDetailStatusReport.findIndex((o) => o.value == s);
          if (index != -1) {
            orderDetailStatusReport[index].count += 1;
          }
        });
      }
    });

    this.orderDetailStatusSumaryOptions;

    return orderDetailStatusReport;
  }

  isOrderDone() {
    return (
      this.orderDetails.findIndex(
        (s) =>
          s.status !== OrderDetailStatus.Delivered &&
          s.categoryId !== this.deliveryProductCategoryId
      ) === -1
    );
  }
}

enum OrderDetailStatusSumary {
  Printed = 5,
  OrderReady = 6,
  Delivered = 3,
  Ordered = 2
}
