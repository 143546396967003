import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BContainer,
  BAvatar,
  BPagination,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BLink,
  BFormDatepicker,
  BTooltip,
  BInputGroupAppend,
  BInputGroupText,
  BDropdownForm,
  BFormTextarea,
  BOverlay,
  BTable,
  BThead,
  BTbody,
  BTh,
  BTd,
  BTr,
  BTfoot,
  BTableSimple
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import { ListVue, listVueMixin } from '@/utility/pagination/list-vue.mixin';
import Pagination from '@/@core/components/paginations/Pagination.vue';
import {
  OrderListViewModel,
  OrderPaymentStatus,
  UpdateOrderPaymentStatusInput,
  UpdateOrderStatusInput,
  OrderStatus,
  DownloadOrderReportInput,
  EmployeeListViewModel,
  OrderDetailViewModel,
  CustomerViewModel,
  OrderPaymentMethod,
  CustomerLevel,
  OrderDetailStatus,
  MasterDataViewModel,
  UpdateOrderPaymentMethodInput
} from '@/api/api';
import {
  producingListNamespace,
  producingListDispatcher,
  ProducingListInput
} from './store/producing-list.module';
import {
  ListGetter,
  ListGetterTypes
} from '@/utility/pagination/get-list.module-type';
import {
  DEFAULT_ORDER_PAGE_SIZE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_OPTIONS,
  getValueOrUndefined
} from '@/utility/pagination/pagination';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import {
  enumToDropdownOptions,
  StringFormat,
  formatCurrency,
  formatDate,
  convertCurrencyToNumber
} from '@/utility/utils';
import router from '@/router';
import vSelect from 'vue-select';
import ApiClientFactory from '@/api/apiClientFactory';
import { mapActions, mapState } from 'vuex';
import {
  EMPLOYEE_STATE_NAMESPACE,
  EmployeeState,
  EmployeeAction
} from '@/store/employee/employee.module-types';
import {
  CustomerAction,
  CustomerGetter,
  CustomerState,
  CUSTOMER_STATE_NAMESPACE
} from '@/store/customer/customer.module-types';
import ProducingStatusSumary from '../ProducingStatusSumary.vue';
import {
  SettingAction,
  SettingState,
  SETTING_STATE_NAMESPACE
} from '@/store/setting/setting.module-types';
import { PrintType } from '@/views/print/orderDetailPrint/OrderDetailPrint';

@Component({
  components: {
    BContainer,
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    BCardText,
    Pagination,
    BImg,
    BBadge,
    BLink,
    vSelect,
    BFormDatepicker,
    BTooltip,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
    BDropdownForm,
    BFormTextarea,
    BOverlay,
    BTable,
    BThead,
    BTbody,
    BTh,
    BTd,
    BTr,
    BTfoot,
    BTableSimple,
    ProducingStatusSumary
  },
  mixins: [
    listVueMixin({
      initialInput: {
        itemsPerPage: DEFAULT_ORDER_PAGE_SIZE,
        searchText: '',
        filterStatus: [] as DropdownOption[],
        filterEmployee: '',
        isOrderForDesign: false,
        isOrderForPrinting: false
      },
      dispatcher: producingListDispatcher,
      debouncedWatchers: [
        (listInput) => listInput.searchText,
        (listInput) => listInput.filterEmployee
      ]
    })
  ],
  computed: {
    ...mapState(CUSTOMER_STATE_NAMESPACE, [CustomerState.customers]),
    ...mapState(EMPLOYEE_STATE_NAMESPACE, [EmployeeState.employees]),
    ...mapState(SETTING_STATE_NAMESPACE, [SettingState.masterData])
  },
  methods: {
    ...mapActions(EMPLOYEE_STATE_NAMESPACE, [EmployeeAction.fetchEmployees]),
    ...mapActions(CUSTOMER_STATE_NAMESPACE, [CustomerAction.fetchCustomers]),
    ...mapActions(SETTING_STATE_NAMESPACE, [SettingAction.fetchMasterData])
  }
})
export default class ProducingList
  extends Vue
  implements ListVue<OrderListViewModel, ProducingListInput> {
  @producingListNamespace.Getter(ListGetter.state)
  readonly listState!: ListGetterTypes<
    OrderListViewModel,
    ProducingListInput
  >[ListGetter.state];
  customers!: CustomerViewModel[];
  fetchCustomers!: () => Promise<void>;
  masterData!: MasterDataViewModel;
  fetchMasterData!: () => Promise<void>;

  @Prop({ type: Boolean, required: false })
  isOrderForDesign: boolean | undefined;

  @Prop({ type: Boolean, required: false })
  isOrderForPrinting: boolean | undefined;

  $refs!: {
    paymentMethodRef: any;
    paymentMethodFormRef: any;
    bankingNoteRef: any;
  };

  employees!: EmployeeListViewModel[];
  // Type the mapped fetchPosts action.
  fetchEmployees!: () => Promise<void>;

  producingListDispatcher = producingListDispatcher;
  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;
  ORDER_STATUS_ENUM = OrderStatus;
  ORDER_PAYMENT_STATUS_ENUM = OrderPaymentStatus;
  formatCurrency = formatCurrency;
  formatDate = formatDate;

  isLoading = false;
  orderSelectedRows: OrderListViewModel[] = [];
  orderDetailStatusOptionFilters: DropdownOption[] = enumToDropdownOptions(
    OrderDetailStatusFilter
  ).map((s) => {
    return {
      value: s.value.toString(),
      text: s.text
    };
  });
  filterOptionButtons: { value: DropdownOption; isSelecting: boolean }[] = [];

  originalOrderDetailStatus: DropdownOption[] = enumToDropdownOptions(
    OrderDetailStatus
  ).map((s) => {
    return {
      value: s.value,
      text: s.text
    };
  });
  get orderDetailStatusOptions(): DropdownOption[] {
    if (this.isOrderForDesign) {
      return enumToDropdownOptions(OrderDetailStatus)
        .filter(
          (s) =>
            (parseInt(s.value) >= 10 && parseInt(s.value) < 20) ||
            parseInt(s.value) === 2 // orderd
        )
        .map((s) => {
          return {
            value: s.value,
            text: s.text,
            count: 0
          };
        });
    }

    return enumToDropdownOptions(OrderDetailStatus)
      .filter((s) => parseInt(s.value) < 10)
      .map((s) => {
        return {
          value: s.value,
          text: s.text,
          count: 0
        };
      });
  }

  get pageSizeOptions() {
    return DEFAULT_PAGE_SIZE_OPTIONS;
  }

  get tabTitle() {
    if (this.isOrderForDesign) return this.$t('DesignManagement');
    return this.$t('ProducingManagement');
  }

  isShowOrderDetailItem(item) {
    return item.categoryId !== this.masterData.deliveryProductCategoryId;
  }

  get employeeOptions(): DropdownOption[] {
    const result: DropdownOption[] = [];
    this.employees.map((s: EmployeeListViewModel) =>
      result.push({ text: s.fullName ?? '', value: s.id })
    );

    result.unshift({
      text: 'Tất cả',
      value: ''
    });
    return result;
  }

  totalCustomerPay = 0;
  depositAmount = 0;
  remainTotalCustomerPay = 0;
  isDownloadingReport = false;
  paymentMethodInput = 0;

  fields = [
    {
      key: 'orderDetail',
      label: 'Chi tiết đơn hàng',
      thStyle: 'width-15 left-border'
    },
    {
      key: 'producingStatus',
      label: 'Tình trạng sản xuất',
      thStyle: 'width-10'
    },
    { key: 'STT', label: 'STT', thStyle: 'width-3' },
    { key: 'serviceName', label: 'Tên mặt hàng' },
    // { key: 'polymeCoverType', label: this.$t('PolymeCoverDetaillbl') },
    // { key: 'processingType', label: this.$t('ProcessTypelbl') },
    { key: 'status', label: 'Trạng thái SX', thStyle: 'width-10' },
    { key: 'height', label: 'Chiều cao' },
    { key: 'weight', label: 'Chiều ngang' },
    { key: 'quantity', label: 'Số lượng' },
    { key: 'note', label: 'Ghi chú', thStyle: 'width-18 right-border' }
  ];

  mounted() {
    if (!this.customers || this.customers?.length === 0) {
      this.fetchCustomers();
    }

    if (!this.masterData) {
      this.fetchMasterData();
    }

    this.filterOptionButtons = this.initButtonSelected();
    this.listState.input.isOrderForDesign = this.isOrderForDesign;
    this.listState.input.isOrderForPrinting = this.isOrderForPrinting;
    this.fetchEmployees();
    producingListDispatcher.load();
    this.getOrderDetailStatusStatistics(this.listState.input.filterEmployee);
  }

  reload() {
    producingListDispatcher.load();
    this.getOrderDetailStatusStatistics(this.listState.input.filterEmployee);
  }

  rowStyleClassFn(row: OrderListViewModel) {
    return OrderPaymentStatus[row.paymentStatus];
  }

  getCatalogueSizeName(width) {
    return this.masterData.categorySizeOptions?.find((s) => s.width == width)
      ?.name;
  }

  updateStatus(id: number, status: number) {
    const client = new ApiClientFactory().orderClient();

    const body = {
      ids: [id],
      status
    } as UpdateOrderStatusInput;

    client.updateStatus(body).then(() => {
      producingListDispatcher.load();
      this.getOrderDetailStatusStatistics(this.listState.input.filterEmployee);
      this.$bvToast.toast(
        StringFormat(
          this.$t('UpdateStatusSuccessfully').toString(),
          this.$t('Order').toString().toLowerCase()
        ),
        {
          title: this.$t('Order').toString(),
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        }
      );
    });
  }

  downloadReport() {
    let from, to;
    if (this.listState.input.dateFrom) {
      from = new Date(
        this.listState.input.dateFrom.getFullYear(),
        this.listState.input.dateFrom.getMonth(),
        this.listState.input.dateFrom.getDate(),
        0,
        0,
        0
      );
    }

    if (this.listState.input.dateTo) {
      // input.dateTo.setDate(input.dateTo.getDate() + 1);
      to = new Date(
        this.listState.input.dateTo.getFullYear(),
        this.listState.input.dateTo.getMonth(),
        this.listState.input.dateTo.getDate(),
        0,
        0,
        0
      );
      to.setDate(to.getDate() + 1);
    }

    const client = new ApiClientFactory().orderClient();
    this.isDownloadingReport = true;
    client
      .report(
        new DownloadOrderReportInput({
          dateFrom: from ? from.getTime() : null,
          dateTo: to ? to.getTime() : null,
          filterStatus: getValueOrUndefined(
            this.listState.input.filterStatus?.map((_) => _.value)
          ),
          orderIds: this.orderSelectedRows.map((s) => s.id)
        })
      )
      .then((response) => {
        this.isDownloadingReport = false;
        const url = window.URL.createObjectURL(
          response?.data as Blob | MediaSource
        );
        const a = document.createElement('a');
        a.href = url;
        a.download = 'report.xlsx';
        a.click();
      });
  }

  onFilterButton(selectedItem: DropdownOption): void {
    this.filterOptionButtons = this.filterOptionButtons.map((item) => {
      return { ...item, isSelecting: false };
    });

    const selectedValueIndex = this.filterOptionButtons.findIndex(
      (s) => s.value.value === selectedItem.value
    );

    const exsitingStatusIndex =
      this.listState.input.filterStatus?.findIndex(
        (s) => s.value === selectedItem.value
      ) ?? -1;

    this.listState.input.filterStatus = [];

    if (exsitingStatusIndex === -1) {
      this.listState.input.filterStatus?.push({
        value: selectedItem.value,
        text: selectedItem.text
      });

      this.filterOptionButtons[selectedValueIndex].isSelecting = true;
    }

    producingListDispatcher.load();
  }

  initButtonSelected() {
    return this.orderDetailStatusOptionFilters.map((val) => {
      return {
        value: val,
        isSelecting: false
      };
    });
  }

  onEmployeeFilterChange(value) {
    this.getOrderDetailStatusStatistics(value?.value ?? '');
    this.listState.input.filterEmployee = value?.value ?? '';
  }

  onChangePaymentMethod(id: string) {
    const client = new ApiClientFactory().orderClient();
    const updatePaymentMethodInput: UpdateOrderPaymentMethodInput = {
      orderIds: [Number.parseInt(id)],
      paymentMethod: this.paymentMethodInput
    } as UpdateOrderPaymentMethodInput;

    client
      .updateOrderPaymentMethod(updatePaymentMethodInput)
      .then(() => {
        producingListDispatcher.load();
        this.$bvToast.toast(
          StringFormat(
            this.$t('UpdatePaymentMethodSuccessfully').toString(),
            this.$t('Order').toString().toLowerCase()
          ),
          {
            title: this.$t('Order').toString(),
            toaster: 'b-toaster-bottom-right',
            variant: 'success'
          }
        );
      })
      .catch((err) => {
        this.$bvToast.toast(err, {
          title: this.$t('Order').toString(),
          toaster: 'b-toaster-bottom-right',
          variant: 'error'
        });
      })
      .finally(() => {
        const el = this.$refs.paymentMethodRef;
        el.hide(true);
        el.focusToggler();
      });
  }

  getRowSpans(dataRow) {
    return dataRow?.orderDetails?.length ?? 0;
  }

  getOrderDetailStatus(value) {
    return (
      this.originalOrderDetailStatus.find((s) => s.value === value)?.text ?? ''
    );
  }

  onPrint(orderId) {
    const newWindow = window.open(
      `/print-order/${orderId}?type=${PrintType.OrderProducing.toString()}`,
      '_blank',
      `width=1000,height=${window.innerHeight}`
    );
    if (newWindow === null) return;
  }

  updateOrderDetailStatus(id, value) {
    const order = this.listState.items.find(
      (s) => s.orderDetails?.findIndex((i) => i.id === id) !== -1
    );
    if (
      order &&
      !this.isOrderAbleToUpdate(order.status, order.orderDetails ?? [])
    ) {
      return;
    }
    const client = new ApiClientFactory().orderClient();

    client.updateOrderDetailStatus(id, value).then(() => {
      producingListDispatcher.load();
      this.$bvToast.toast(
        StringFormat(
          this.$t('UpdateStatusSuccessfully').toString(),
          this.$t('Order').toString().toLowerCase()
        ),
        {
          title: this.$t('Order').toString(),
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        }
      );
    });
  }

  getOrderDetailStatusStatistics(employeeId) {
    const client = new ApiClientFactory().orderClient();
    client.getOrderDetailStatusStatistics(employeeId).then((result) => {
      this.filterOptionButtons = this.filterOptionButtons.map((item) => {
        let statisticValue = 0;
        statisticValue = result ? result[item.value.value] : 0;

        return { ...item, count: statisticValue };
      });
    });
  }

  isOrderAbleToUpdate(
    orderStatus: OrderStatus,
    orderDetails: OrderDetailViewModel[]
  ) {
    const inValidToUpdate =
      orderStatus === OrderStatus.Delivered ||
      orderDetails.findIndex(
        (s) => s.status !== OrderDetailStatus.Delivered
      ) === -1;

    if (inValidToUpdate) {
      this.$bvToast.toast('Không thể chỉnh sửa đơn hàng đã hoàn thành', {
        title: this.$t('Order').toString(),
        toaster: 'b-toaster-bottom-right',
        variant: 'danger'
      });
    }

    return !inValidToUpdate;
  }

  getServiceName(orderDetail: OrderDetailViewModel) {
    const productName = orderDetail.serviceName;
    if (
      orderDetail.categoryId &&
      orderDetail.categoryId === this.masterData.catalogueCategoryId
    ) {
      return `<div>${orderDetail.serviceName} <br>
              <span class="service-name-subtext">
                - ${orderDetail.catalogueProductName} <br>
                - ${orderDetail.polymeCoverTypeName} <br>
                - ${this.getCatalogueSizeName(orderDetail.width)} <br> 
                - ${orderDetail.totalPage} Trang
              </span>
              </div>`;
    }

    let productNameCell = `<div>${productName} <br> <span class="service-name-subtext">`;
    if (
      orderDetail.processingSettingModels &&
      orderDetail.processingSettingModels.length > 0
    ) {
      orderDetail.processingSettingModels?.forEach((item) => {
        productNameCell += `- ${
          item?.processingSettingModelItems
            ? item.processingSettingModelItems[0].name
            : ''
        } <br>`;
      });

      if (
        this.masterData.assignEmployeeCategoryIds?.includes(
          orderDetail.categoryId
        )
      ) {
        productNameCell += `<span class="text-info">NVTK: ${
          orderDetail.employee ?? ''
        }</span> `;
      }
      return (productNameCell += '</span></div>');
    }

    productNameCell += `
      ${
        orderDetail.polymeCoverTypeName
          ? '- ' + orderDetail.polymeCoverTypeName + '<br>'
          : ''
      }
      ${
        orderDetail.processingTypeName
          ? '- ' + orderDetail.processingTypeName + '<br>'
          : ''
      }
      ${
        orderDetail.processingTypeName2
          ? '- ' + orderDetail.processingTypeName2
          : ''
      }
      {0}
    </div>`;

    if (
      this.masterData.assignEmployeeCategoryIds?.includes(
        orderDetail.categoryId
      )
    ) {
      productNameCell = StringFormat(
        productNameCell,
        `<span class="text-info"><strong>${
          orderDetail.employee ? `NV thực hiện: ${orderDetail.employee}` : ''
        }</strong></span> `
      );
    } else {
      productNameCell = StringFormat(productNameCell, '');
    }

    return productNameCell;
  }
}

enum OrderDetailStatusFilter {
  InProgress = 2,
  InPrinting = 4,
  Printed = 5,
  Processed = 7,
  OrderReady = 1,
  Delivered = 3
}
